@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth; /* Omogućava glatko pomeranje */
}

body{
    background-color: #4f4f4f;
    font-family: 'Poppins', sans-serif; /* Postavi novi font */
}

/* U svom CSS fajlu */
.swiper-button-next,
.swiper-button-prev {
   color: #696969; /* Zamenite sa željenom Tailwind bojom */
}

